import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { uploadMediaToS3 } from '@assured/utilities/src/uploadMediaToS3';

import { config } from '../../config';
import { useMobileDetect } from '../../hooks';
import ExampleIllustration from '../elements/ExampleIllustration';
import ProgressBar from '../ProgressBar';

import type {
  StepComponentFC,
  StepComponentSharedProps,
  UploadStepComponentSpec,
} from '@assured/step-renderer';
import { DocumentIcon } from '@heroicons/react/solid';

const VideoRecorder = React.lazy(() => import('../VideoRecorder'));

interface PreviewableFile extends File {
  preview: string;
  exifData: any;
}
interface UploadValueInner {
  source: string;
  lastModifiedAt: string | null;
  exifData: string;
  [key: string]: any;
}
type UploadValue = UploadValueInner | (UploadValueInner | { id: string })[];

interface UploadProps
  extends StepComponentSharedProps<
    UploadStepComponentSpec,
    UploadValue | null
  > {
  forceSubmit: () => void;
  additionalButtons: React.ReactNode;
}

const truncateFileName = (fileName: string, maxLength: number): string => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return fileName.length > maxLength
      ? `${fileName.slice(0, maxLength - 3)}...`
      : fileName;
  }

  const extension = fileName.slice(lastDotIndex);
  const baseName = fileName.slice(0, lastDotIndex);

  const maxBaseLength = maxLength - extension.length - 3;

  if (baseName.length > maxBaseLength) {
    return `${baseName.slice(0, maxBaseLength)}...${extension}`;
  }

  return fileName;
};

const UnknownFileComponent = ({ file }: { file: PreviewableFile }) => {
  return (
    <div className="flex items-center p-4 border border-gray-200 bg-gray-50 rounded-md mx-auto my-2">
      <DocumentIcon className="text-[##1E293B] h-6 w-6" />
      <div className="text-cool-gray-500 ml-3">
        {truncateFileName(file.name, 35)}
      </div>
    </div>
  );
};

const Upload: StepComponentFC<UploadProps> = ({
  step_component,
  primaryValue,
  updateValue,
  forceSubmit,
  className,
  additionalButtons,
  workflowChannel,
  onSidekickCommand,
}) => {
  const { isMobile } = useMobileDetect();

  const [files, setFiles] = useState<PreviewableFile[]>([]);
  const [progress, setProgress] = useState(0);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [dispatchedUpload, setDispatchedUpload] = useState(false);

  const uploadFile = (files: File[]) => {
    const setNewFile = (file: PreviewableFile) => {
      setFiles(existingFiles => [...existingFiles, file]);
    };

    uploadMediaToS3({
      files,
      serverEndpoint: config.endpoint,
      onPreprocessComplete: setNewFile,
      setProgress,
      onBatchFinished: onFinish,
      onError,
      mode: step_component.mode,
    });
  };

  const fileAcceptMap: Record<string, string[] | undefined> = {
    image: ['image/*'],
    document: step_component.allowed_content_types,
  };

  const { getRootProps, getInputProps, rejectedFiles, isDragActive } =
    useDropzone({
      onDrop: acceptedFiles => {
        uploadFile(acceptedFiles);
        if (rejectedFiles.length > 0) {
          console.warn('some files were rejected', rejectedFiles);
        }
      },
      onDropRejected: filesWithErrors => {
        if (filesWithErrors.length > 0) {
          // eslint-disable-next-line no-alert
          window.alert(
            // were are using an outdated version of the library that does not provide reasons for failure on each rejected file
            `Unable to upload the following file(s) due to content type or file size constraints:\n\n${filesWithErrors
              .map(file => file.name)
              .join('\n')}`,
          );
        }
      },
      accept: fileAcceptMap[step_component.mode as keyof typeof fileAcceptMap],
      maxSize: step_component.maximum_content_size,
      multiple: step_component.multiple || false,
    });

  const onError = (e: string) => {
    console.error(e);
    window.alert(JSON.stringify(e));
  };

  const onFinish = (data: any) => {
    setUploadedData(d => d.concat([data]));
  };

  const hasFileNameMatch = (source?: string, target?: string) => {
    const cleanSource = source?.replaceAll?.(' ', '');
    if (!cleanSource || !target) {
      return false;
    }
    return target.includes(cleanSource);
  };

  useEffect(() => {
    if (
      userConfirmed &&
      uploadedData.length &&
      uploadedData.length === files.length &&
      !dispatchedUpload
    ) {
      const values = uploadedData.map(d => {
        const file =
          files.find(f => hasFileNameMatch(f.name, d.filename)) || files[0];
        return {
          ...step_component.value,
          source: d.filename,
          lastModifiedAt: file.lastModified
            ? new Date(file.lastModified).toISOString()
            : null,
          exifData: JSON.stringify(file.exifData),
        };
      });
      setDispatchedUpload(true);
      updateValue(
        step_component.field,
        step_component.multiple
          ? (
              step_component.existing_hidden_values || ([] as UploadValue)
            ).concat(values)
          : values[0],
      );
    }
  }, [userConfirmed, uploadedData, files, dispatchedUpload]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  if (workflowChannel === 'sidekick') {
    return (
      <div className={classNames(className, 'mt-4')}>
        <button
          type="button"
          onClick={() => {
            // Send sidekick backend request
            onSidekickCommand?.({ type: 'DIGITAL_REQUEST_MEDIA' });
            // Skip to next step
            forceSubmit();
          }}
          className="btn btn-blue"
        >
          Send media request
        </button>
        <div className="">
          <button
            type="button"
            className="btn btn-subtle"
            onClick={forceSubmit}
          >
            Never mind
          </button>
        </div>
      </div>
    );
  }

  const modeLabelConfigurations: Record<
    string,
    { label: string; mobileAction?: string }
  > = {
    image: {
      label: 'photo',
      mobileAction: 'Tap here to take or upload one or more',
    },
    video: {
      label: 'video',
    },
    document: {
      label: 'document',
    },
  };

  const getItemTypeLabel = (useFileCountPlurality = false) => {
    const typeConfig =
      modeLabelConfigurations[step_component.mode] ||
      modeLabelConfigurations.image;
    const isPlural = useFileCountPlurality
      ? files.length > 1
      : step_component.multiple || false;
    return isPlural ? `${typeConfig.label}s` : typeConfig.label;
  };

  const getActiveDragUploadInstructionLabel = () => {
    return `Drop the ${getItemTypeLabel()} here...`;
  };

  const getUploadInstructionLabel = () => {
    const typeConfig =
      modeLabelConfigurations[step_component.mode] ||
      modeLabelConfigurations.image;
    const itemType = getItemTypeLabel();
    const mobileText = `${
      typeConfig.mobileAction || 'Tap here to upload'
    } ${itemType}`;

    return isMobile
      ? mobileText
      : `Drag and drop ${itemType} here, or click to select`;
  };

  return (
    <div className={classNames(className, 'mt-4')}>
      {files.length === 0 ? (
        <React.Fragment>
          {step_component.example_illustration && (
            <ExampleIllustration type={step_component.example_illustration} />
          )}
          {step_component.mode === 'video' ? (
            <VideoRecorder
              className="mb-6 rounded-md overflow-hidden"
              onSubmit={(blob, extension) => {
                setUserConfirmed(true);
                uploadFile([new File([blob], `video${extension}`)]);
              }}
            />
          ) : (
            <div
              {...getRootProps()}
              className={classNames(
                'py-4 rounded border-4 border-dashed border-cool-gray-400 text-cool-gray-600 cursor-pointer bg-cool-gray-100 outline-none w-full h-full focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150',
                isDragActive && 'bg-cool-gray-200',
              )}
              style={{ paddingLeft: '22%', paddingRight: '22%' }}
            >
              <input
                aria-labelledby="upload-label"
                tabIndex={0}
                {...getInputProps()}
              />
              <p id="upload-label">
                {isDragActive
                  ? getActiveDragUploadInstructionLabel()
                  : getUploadInstructionLabel()}
              </p>
            </div>
          )}
          {(
            Array.isArray(step_component.existing_source)
              ? step_component.existing_source.length
              : step_component.existing_source
          ) ? (
            <button
              className="mt-4 btn btn-subtle mx-0 w-full block"
              onClick={forceSubmit}
            >
              Use previously uploaded
              {step_component.existing_source &&
              step_component.existing_source.length > 1
                ? ' images'
                : ' image'}
              {(Array.isArray(step_component.existing_source)
                ? step_component.existing_source
                : [step_component.existing_source]
              ).map(
                source =>
                  source && (
                    <img
                      alt={source}
                      key={source}
                      src={source}
                      className="h-20 mt-2 mx-auto"
                    />
                  ),
              )}
            </button>
          ) : null}
          {!step_component.unskippable ? (
            <button
              type="button"
              className="mt-4 btn btn-subtle mx-0 py-2 w-full block"
              data-testid="manuallyEnterData"
              onClick={forceSubmit}
            >
              {step_component.skip_label ||
                `I can't ${
                  isMobile && step_component.mode === 'image'
                    ? 'take'
                    : 'upload'
                } ${
                  step_component.multiple
                    ? `these ${getItemTypeLabel()}`
                    : `this ${getItemTypeLabel()}`
                }`}
            </button>
          ) : null}
          {additionalButtons}
        </React.Fragment>
      ) : (
        <div className="outline-none">
          {step_component.mode !== 'video' ? (
            files
              .sort(a => (a.preview ? 1 : -1))
              .map(file =>
                file.preview ? (
                  <img
                    alt={file.name}
                    key={file.name}
                    src={file.preview}
                    className="h-48 my-6 mx-auto border shadow-xl"
                  />
                ) : (
                  <UnknownFileComponent key={file.name} file={file} />
                ),
              )
          ) : (
            <div className="my-6" />
          )}
          {userConfirmed ? (
            <div>
              <div className="text-sm text-cool-gray-600 mb-2">
                Uploading {getItemTypeLabel(true)}...
              </div>
              <ProgressBar progress={progress} className="w-64 mx-auto" />
            </div>
          ) : (
            <button
              type="button"
              onClick={() => setUserConfirmed(true)}
              className="btn btn-blue"
            >
              Upload {getItemTypeLabel(true)}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default Upload;
